<template>
  <div class="wrap-lesson" v-if="unitstory" :style="backgroundColor">
    <div class="container">
      <div class="row">
        <div class="wrap-banner" :style="bannerBackgroundImage">
          <div class="row">
            <div class="lesson-image unitstory-image-mr col-md-3 col-sm-3 col-xs-3 offset-md-1 offset-sm-1 offset-xs-1" v-if="image">
              <img :src="image" class="lesson-image" />
            </div>
            <div class="lesson-title">
              <span
                class="lesson-title"
                v-tooltip="unitstory.title"
                :style="{
                  color: unitstory.color,
                  textShadow: ' 2px 2px rgba(0,0,0,0.4)',
                  paddingLeft: unitstory.titlePositionLeft + 'px',
                  top: unitstory.titlePositionTop + 'px'
                }"
                style="position: relative;"
                >{{ unitstory.title }}</span
              >
              <essential-question
                v-show="false"
                v-if="essentialQuestionText"
                :key="unitstory.essentialQuestion.id"
                :audio="essentialQuestionAudio"
                :message="essentialQuestionText"
              />
            </div>
          </div>
        </div>
        <span v-if="components.length == 0" class="centered">{{
          $t("noContentToDisplayMessage")
        }}</span>
        <template v-else>
          <div class="lesson-container flex-100">
            <component
              v-for="component in components"
              :is="component.paragraph.type"
              :key="component.paragraph.id"
              :content="component.paragraph"
            />
          </div>
          <div class="col-12 prev-button d-flex pl-0 pr-0">
            <button class="btn round primary back" @click="$router.go(-1)">
              {{ $t("back") }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- <div class="lesson" v-if="unitstory">
    <div class="lesson-banner row" :style="bannerBackgroundImage">
      <div class="banner-background" :style="backgroundColor"></div>
      <img :src="image" class="lesson-image" />
      <div class="column">
        <span
          class="lesson-title"
          v-tooltip="unitstory.title"
          :style="{
            color: unitstory.color,
            textShadow: ' 2px 2px rgba(0,0,0,0.4)',
          }"
          >{{ unitstory.title }}</span
        >
        <essential-question
          v-show="false"
          v-if="essentialQuestionText"
          :key="unitstory.essentialQuestion.id"
          :audio="essentialQuestionAudio"
          :message="essentialQuestionText"
        />
      </div>
    </div>
    <div class="lesson-background" :style="backgroundColor"></div>
    <span v-if="components.length == 0" class="centered">{{
      $t("noContentToDisplayMessage")
    }}</span>
    <template v-else>
      <div class="lesson-container flex-container pb-60">
        <component
          v-for="component in components"
          :is="component.paragraph.type"
          :key="component.paragraph.id"
          :content="component.paragraph"
        />
      </div>
      <div class="col-12 d-flex pb-20px">
        <button class="btn round primary back" @click="$router.go(-1)">
          {{ $t("back") }}
        </button>
      </div>
    </template>
  </div> -->
</template>

<script>
// import AuthenticationService from "@/services/AuthenticationService.js";
// import { Role } from "@/utils/Roles.js";
import LessonParagraph from "@/components/Lesson/LessonParagraph.vue";
import LessonTextContent from "@/components/Lesson/LessonTextContent.vue";
import VideoContent from "@/components/Lesson/VideoContent.vue";
import LessonCard from "@/components/Lesson/LessonCard.vue";
import SectionTitle from "@/components/Lesson/SectionTitle.vue";
import InteractiveMap from "@/components/Lesson/InteractiveMap.vue";
import InteractiveLesson from "@/components/Lesson/InteractiveLesson.vue";
import GlobalConnections from "@/components/Lesson/GlobalConnections.vue";
import EssentialQuestion from "@/common/EssentialQuestion.vue";
import OnlyImages from "@/components/Lesson/OnlyImages.vue";

export default {
  name: "Story",
  components: {
    paragraph: LessonParagraph,
    card: LessonCard,
    "section-title": SectionTitle,
    "text-content": LessonTextContent,
    InteractiveMap,
    InteractiveLesson,
    VideoContent,
    EssentialQuestion,
    "only-images": OnlyImages,
    GlobalConnections,
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    unitstory() {
      return this.$store.state.currentStory;
    },
    image() {
      return this.getImage(this.unitstory.imageName);
    },
    bannerBackgroundImage() {
      return {
        backgroundImage: `url(${this.getImage(
          this.unitstory.headerBackgroundName
        )})`,
      };
    },
    components() {
      return this.unitstory.components;
    },
    backgroundColor() {
      return {
        backgroundColor: this.unitstory.background || "",
        opacity: this.unitstory.opacity / 100 || 1,
      };
    },
    essentialQuestionText() {
      if (!this.unitstory || !this.unitstory.essentialQuestion) return null;
      let essentialQuestionText = this.unitstory.essentialQuestion.question;
      return essentialQuestionText[this.locale].length > 0
        ? essentialQuestionText
        : null;
    },
    essentialQuestionAudio() {
      if (!this.unitstory.essentialQuestion) return null;
      return this.unitstory.essentialQuestion.audio;
    },
  },
  methods: {
    getImage(image) {
      if (image === 0 || !image) return;
      return `${process.env.VUE_APP_CDN}images/${image}`;
    },
    loadUnitStory() {
      this.$store
        .dispatch("loadStory", this.$route.params.unitstoryId)
        .finally(() => {
          this.isLoadingInformation = false;
        });

      this.$store.dispatch("loadCurrentUnit", this.$route.params.unitId);
    },
  },
  created() {
    this.loadUnitStory();
  },
  watch: {
    $route: function () {
      this.loadUnitStory();
    },
  },
  mounted() {
    this.startTrackingPage({ sectionName: "Lessons" });
    this.$store.commit("setAnalyzedManagementSection", "Lessons");
  },
  beforeDestroy() {
    // if (this.unitstory) this.setLessonCompleted();
    this.$store.commit("setAnalyzedManagementSection", "");
    this.stopTrackingPage({ sectionName: "Lessons" });
  },
};
</script>

<style lang="less" scoped>
.flex-100{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.lesson {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  z-index: 0;
  margin-top: 104px;
  height: 100vh;
  background-color: white;

  .lesson-banner {
    width: 1280px;
    height: 250px;
    max-width: 1280px;
    max-height: 250px;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .info-icon {
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: auto;
    }

    .lesson-image {
      height: -webkit-fill-available !important;
      width: auto;
      margin-left: 30px;
      max-height: 244px;
      max-width: 468px;
    }
    .column {
      // margin:auto;
      margin-top: 37px;
      margin-left: 32px;
    }
    .lesson-title {
      font-family: "Roboto", sans-serif;
      max-width: 820px;
      min-height: 41px;
      color: #3a4dfd;
      font-size: 33.64px;
      font-weight: 600;
      letter-spacing: 0.25px;
      padding-left: 16px;
      // line-height: 41px;
      white-space: normal;
      max-height: 120px;
    }
    .essential-question {
      margin-top: auto;
      max-height: 136px;
      padding: 8px;
      box-sizing: border-box;
      margin-right: auto;
      :global(span) {
        height: auto;
        min-height: 16px;
        white-space: normal;
        max-height: 100%;
        width: auto;
        max-width: 505px;
        overflow-y: auto;
        font-size: 14px;
        line-height: 16px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      :global(.brain-image) {
        height: 40px;
        width: 40px;
      }
    }
  }

  .lesson-container {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    &.pb-60 {
      padding-bottom: 20px;
    }
  }
  .btn {
    margin-left: auto;
    margin-top: 96px;
  }

  .lesson-background,
  .banner-background {
    z-index: -2;
    position: fixed;
    top: 0;
    min-height: 100%;
    width: 100%;
  }

  .banner-background {
    position: absolute;
    z-index: -1;
    width: 100%;
    max-height: 250px;
    height: 250px;
    left: 50%;
    transform: translate(-50%);
  }

  .controls-section {
    margin: 0 auto;
    width: 1280px;
    padding-bottom: 60px;
  }
}
.d-flex {
  display: flex;
  justify-content: flex-end;
  // padding-bottom: 30px;
}
.wrap-lesson {
  font-family: "Roboto", sans-serif !important;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  z-index: 0;
  margin-top: 104px;
  height: calc(100% - 104px);
  background-color: white;
  // padding-bottom: 30px;
    .wrap-banner {
      min-height: 242px;
      width: 100%;
      max-height: 250px;
      padding: 15px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 30px;
      .teacher-icon {
        text-align: center;
        padding-top: 15px;
      }
      .lesson-image {
        img {
          max-width: 100%;
          max-height: 244px;
        }
      }
      .lesson-title {
        color: #3a4dfd;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0.25px;
        white-space: normal;
        margin-left: 5px;
      }
    }
}
.unitstory-image-mr{
  margin-left: 84px;
}
</style>